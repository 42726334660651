<template>
  <v-container>
    <!-- Header -->
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="6">
        <v-toolbar class="primary darken-1 white--text">
          <v-spacer></v-spacer>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row class="mt-2 ml-1" justify="center">
      <v-col cols="12" lg="2">
        <core-select
          label="Language"
          v-model="language"
          :items="allLanguages"
          :loading="allLanguages.length == 0"
          @change="waterfallCategories">
        </core-select>
      </v-col>
      <v-col cols="12" lg="2">
        <core-select label="Language Level" v-model="level" :items="allDifficulties" clearable> </core-select>
      </v-col>
      <v-col cols="12" lg="2">
        <core-select label="Part of Speech" v-model="partOfSpeech" :items="allPartsOfSpeech" clearable>
        </core-select>
      </v-col>
      <v-col cols="12" lg="2">
        <core-select
          label="Category"
          v-model="category"
          :items="categories"
          clearable
          :loading="categoriesLoading">
        </core-select>
      </v-col>
      <v-col cols="12" lg="2" class="mt-2">
        <core-btn-secondary :loading="loading" @click="updateStudyList">
          Update<v-icon>mdi-refresh</v-icon>
        </core-btn-secondary>
      </v-col>
      <v-col cols="12" lg="2" class="mt-2">
        <core-btn-secondary :loading="loading" @click="navigate('flashcards', 'learn')">
          Learn<v-icon>mdi-arrow-right</v-icon>
        </core-btn-secondary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as functions from "@/javascript/functions"

export default {
  components: {},

  data: function () {
    return {
      allPartsOfSpeech: ["Noun", "Verb", "Adjective"],
      translations: [],
      loading: false,
      categoriesLoading: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    srsSettings() {
      return this.user.srs_user
    },
    allDifficulties() {
      return this.language == "Japanese"
        ? ["N5 (Easiest)", "N4", "N3", "N2", "N1 (Hardest)"]
        : [1, 2, 3, 4, 5]
    },
    allLanguages() {
      if (this.user.is_superuser) {
        return this.$store.state.allLanguages.map((language) => language.english_name)
      } else {
        return ["Japanese", "Chinese"]
      }
    },
    categories() {
      return this.$store.state.allCategories
    },
    language: {
      get() {
        return this.srsSettings.language
      },
      set(val) {
        this.srsSettings.language = val
      },
    },
    level: {
      get() {
        return this.srsSettings.level
      },
      set(val) {
        this.srsSettings.level = val
      },
    },
    computedLevel() {
      return this.level.split(" ")[0]
    },
    partOfSpeech: {
      get() {
        return this.srsSettings.part_of_speech
      },
      set(val) {
        this.srsSettings.part_of_speech = val
      },
    },
    category: {
      get() {
        return this.srsSettings.category
      },
      set(val) {
        this.srsSettings.category = val
      },
    },
    highlightedText() {
      return this.$store.state.highlightedText
    },
    topTranslations() {
      return this.translations.slice(0, 4)
    },
  },

  methods: {
    navigate(app, pageName) {
      let pageSlug = functions.nameToSlug(pageName)
      let appSlug = functions.nameToSlug(app)

      this.$router.push({
        name: "App Page",
        params: { app_slug: appSlug, page_slug: pageSlug },
      })
    },
    async updateStudyList() {
      this.loading = true
      try {
        if (this.partOfSpeech == null) this.partOfSpeech = ""
        let params = `language=${this.language}&part_of_speech=${this.partOfSpeech}`
        if (this.level) params = `${params}&level=${this.computedLevel}`
        if (this.partOfSpeech) params = `${params}&part_of_speech=${this.partOfSpeech}`
        if (this.category) params = `${params}&category=${this.category}`

        await this.$django_api.get(`srs/lexeme?${params}&new_words=True&pagination=1`).then((response) => {
          this.$store.state.srsNewWordList = response.data.lexemes
          this.$store.state.srsNewWordTotal = response.data.total
        })
        await this.$django_api.get(`srs/userword?${params}&stage=Recall`).then((response) => {
          this.$store.state.srsRecallList = response.data
        })
        await this.$django_api.get(`srs/userword?${params}&stage=Production`).then((response) => {
          this.$store.state.srsProductionList = response.data
        })
        await this.$django_api.get(`srs/userword?${params}&stage=Play`).then((response) => {
          this.$store.state.srsPlayList = response.data
        })

        let payload = {
          id: this.user.srs_user.id,
          language: this.language,
          level: this.level,
          part_of_speech: this.part_of_speech,
          category: this.category,
        }

        await this.$django_api.put("srs/user/", payload).then((response) => {
          this.user.srs_user = response.data
          this.$store.dispatch("set_user", this.user)
          this.loading = false
        })
        // Load the rest of words slowly - we don't really need this IMO
        // this.$django_api
        //   .get(`srs/lexeme?${params}&new_words=True&pagination=2`)
        //   .then((response) => {
        //     this.$store.state.srsNewWordList = this.$store.state.srsNewWordList.concat(
        //       response.data
        //     )
        //   })
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    async waterfallCategories() {
      this.categoriesLoading = true
      this.category = ""

      await this.$django_api.get(`srs/categories?language=${this.language}`).then((response) => {
        this.$store.state.allCategories = response.data
      })

      this.categoriesLoading = false
    },
  },
  mounted() {},
  watch: {
    srsSettings: {
      handler(val) {},
      deep: true,
    },
  },
}
</script>

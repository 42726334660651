<template>
  <v-container v-if="wordList.length == 0">
    <v-row class="justify-center align-center">
      <v-col class="justify-center align-center">
        <v-card class="pa-4">
          <span v-if="dataLoading">
            <v-progress-circular v-if="dataLoading" color="primary" :size="200" :width="4" indeterminate>
              Data Loading...</v-progress-circular
            >
          </span>
          <span v-else class="secondary--text">
            <b> Great work! </b>You've practiced all your recall words for today. <br /><br />
            Check in again tomorrow or go to the "Learn" or "Production" tabs to keep studying.
            <br /><br />

            <core-btn-secondary @click="navigate('flashcards', 'learn')">Learn</core-btn-secondary>
            <br /><br />
            <core-btn-secondary @click="navigate('flashcards', 'production')">Production</core-btn-secondary>
          </span>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row>
      <v-col sm="12" md="10" lg="7" xl="6">
        <v-card class="pa-4">
          <!-- Title -->
          <v-row class="d-flex justify-center">
            <v-col class="d-flex justify-center align-center">
              <!-- Word -->
              <v-tooltip color="secondary" top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <h3 v-if="topWord.lexeme.language in ['Japanese', 'Chinese']" class="primary--text">
                      {{ topWord.lexeme.word }}
                      {{ flipped ? `(${topWord.lexeme.pronounciation})` : "" }}
                    </h3>
                    <h3 v-else class="primary--text">
                      {{ flipped ? topWord.lexeme.word : "" }}
                    </h3>
                  </span>
                </template>
                <span>Meanings: {{ topWord.lexeme.english_meanings }} </span>
              </v-tooltip>
              <!-- Dictionary URL -->
              <v-tooltip color="secondary" right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="openURL(dictionaryURL)">
                    <v-icon small>mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                <span> Open in Dictionary</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <!-- Image -->
          <v-row class="d-flex justify-center">
            <v-col cols="6" class="d-flex justify-center">
              <v-img class="image" :src="topWord.image_url" :width="300"></v-img>
            </v-col>
          </v-row>
          <!-- Sentence -->
          <v-row class="d-flex justify-center">
            <v-col class="d-flex justify-center align-center">
              <v-tooltip color="secondary" top>
                <template v-slot:activator="{ on, attrs }">
                  <span class="text-body-1" v-bind="attrs" v-on="on">
                    {{ blankedSentence }}
                  </span>
                </template>
                <span class="ma-1">
                  <v-row>{{ topWord.sentence_translation }}</v-row>
                  <v-row v-if="topWord.sentence_pronounciation && flipped">
                    <span class="text-body-1" v-html="topWord.sentence_pronounciation"></span>
                  </v-row>
                </span>
              </v-tooltip>

              <v-tooltip color="secondary" right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="openURL(topWord.sentence_url)">
                    <v-icon small>mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                <span> Open in Tatoeba</span>
              </v-tooltip>
            </v-col>
            <!-- Audio -->
            <v-col v-if="flipped" cols="12" class="d-flex justify-center">
              <audio controls autoplay :src="topWord.sentence_audio_src"></audio
            ></v-col>
          </v-row>
          <!-- hint -->
          <v-row class="d-flex justify-center">
            <v-col class="d-flex justify-center align-center">
              <v-tooltip v-if="!flipped && topWord.hint" color="secondary" right>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"> Hint </span>
                </template>
                <span> {{ topWord.hint }}</span>
              </v-tooltip>
              <v-textarea v-else label="Notes" v-model="topWord.hint" outlined> </v-textarea>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col sm="12" md="2" xl="1" class="mt-md-5">
        <v-row v-if="!flipped">
          <v-col class="d-flex justify-center">
            <core-btn-primary :loading="loading" @click="flip()">
              Flip <v-icon>mdi-refresh</v-icon>
            </core-btn-primary>
          </v-col>
        </v-row>
        <v-row v-if="flipped">
          <v-col class="d-flex justify-center">
            <core-btn-primary inverted :loading="loading" @click="submit(false)">
              Wrong <v-icon>mdi-close</v-icon>
            </core-btn-primary>
          </v-col>
        </v-row>
        <v-row v-if="flipped">
          <v-col class="d-flex justify-center">
            <core-btn-secondary :loading="loading" @click="submit(true)">
              Correct <v-icon>mdi-check</v-icon>
            </core-btn-secondary>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <br />
    <hr />
    <translator> </translator>
  </v-container>
</template>

<style scoped>
.image:hover {
  transition: 0.4s ease;
  opacity: 0.7;
}
</style>

<script>
import * as functions from "@/javascript/functions"
import translator from "@/components/SRS/translator.vue"

export default {
  components: {
    "translator": translator,
  },

  data: function () {
    return {
      loading: false,
      flipped: false,
    }
  },
  computed: {
    dataLoading() {
      return this.$store.state.dataLoading
    },
    user() {
      return this.$store.state.user
    },
    wordList() {
      return this.$store.state.srsRecallList
    },
    topWord() {
      return this.wordList.length > 0 ? this.wordList[0] : { lexeme: {} }
    },
    blankedSentence() {
      if (this.topWord) {
        if (this.topWord.sentence_text) {
          return this.topWord.sentence_text.replace(this.topWord.lexeme.word, "____")
        }
      }
      return ""
    },
    dictionaryURL() {
      return `https://japandict.com/${this.topWord.lexeme.word}`
    },
  },

  methods: {
    flip() {
      this.flipped = true
    },
    openURL(url) {
      window.open(url, "_href")
    },
    navigate(app, pageName) {
      let pageSlug = functions.nameToSlug(pageName)
      let appSlug = functions.nameToSlug(app)

      this.$router.push({
        name: "App Page",
        params: { app_slug: appSlug, page_slug: pageSlug },
      })
    },
    async submit(success) {
      this.loading = true

      let payload = {
        lexeme_id: this.topWord.lexeme.id,
        hint: this.topWord.hint,
        success: success,
      }

      await this.$django_api.post(`srs/study/`, payload).then((response) => {
        Object.assign(this.topWord, response.data)
        this.wordList.shift()
      })

      this.flipped = false
      this.loading = false
    },
  },
  mounted: async function () {},
}
</script>

import Vue from "vue"
import VueRouter from "vue-router"
import store from "./store"
import * as auth from "./auth"
import * as functions from "./functions"
import { Permissions } from "./constants"
import errors from "@/views/errors"
import dynamic from "@/views/Dynamic"
import landing_page from "@/views/landing_page"
import user_settings from "@/views/user_settings"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Landing Page",
    component: landing_page,
    // meta: {requiresAuth: true}
  },
  {
    path: "/user_settings",
    name: "User Settings Page",
    component: user_settings,
    meta: { requiresAuth: true },
  },
  {
    path: "/app/:app_slug/:page_slug",
    name: "App Page",
    component: dynamic.app_page,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/app/:app_slug",
    name: "App Home",
    component: dynamic.app_home,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/401",
    name: "Not Authorized",
    component: errors.not_authorized,
  },
  {
    path: "/403",
    name: "Forbidden",
    component: errors.forbidden,
  },
  {
    path: "/500",
    name: "Server Error",
    component: errors.server_error,
  },
  {
    path: "*",
    name: "Page Not Found",
    component: errors.page_not_found,
  },
]

const router = new VueRouter({
  mode: "history",
  routes: routes,
})

router.beforeEach((to, from, next) => {
  let authorized = true

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (store.state.auth_token) {
      if ("page_slug" in to.params) {
        let page = getPage(to.params.page_slug)
        if (page != null) {
          authorized = authorizePage(page, store.state.user)
        }
      }
    } else {
      if (to.params.page_slug != 'about'){
        authorized = false

      }
    }
  }



  if (authorized) {
    next()
  } else if (!store.state.auth_token) {
    auth.login()
  } else {
    next("/403")
  }
})

// Master list of all pages that can be navigated to, separated by app.
// Each app has some meta information (title, icon, home page) as well as a list of pages. The meta information is what appears in the app bar at the top of the page.
// Each list of pages has page groups and then finally page nodes. These are what appear in the nav bar along the side of the page.
// Access can be restricted to any page by adding a 'requiredPermissions' tag.
const navPages = {
  home: {
    meta: {
      title: "Home",
      icon: "mdi-home",
      home: "landing_page",
    },
  },
  flashcards:
  {
    meta: {
      title: "Flashcards",
      icon: "mdi-card-multiple",
      home: "learn"
    },
    pages: {
      Flashcards: [
        {
          displayName: "Learn",
          icon: "mdi-apple",
        },
        {
          displayName: "Recall",
          icon: "mdi-thought-bubble-outline",
        },
        {
          displayName: "Production",
          icon: "mdi-pencil-circle-outline",
        },
        {
          displayName: "Play",
          icon: "mdi-controller",
        },
        {
          displayName: "Settings",
          icon: "mdi-cog",
        },
      ]
      }

    },
    about: {
      meta: {
        title: "About",
        icon: "mdi-information-outline",
        home: "about",
      },
      pages: {
        About: [
          {
            displayName: "About",
            icon: "mdi-information-outline",
          },
        ],
      }
    },
  new_hope: {
      meta: {
        title: "New Hope",
        icon: "mdi-cross-outline",
        home: "podcast",
        requiredPermissions: ["is_new_hope_admin"]
      },
      pages: {
        Church: [
          {
            displayName: "Podcast",
            icon: "mdi-podcast",
            requiredPermissions: ["is_new_hope_admin"]
          },
        ],
      }
    },
  dev: {
    meta: {
      title: "Dev",
      icon: "mdi-key-variant",
      home: "dev_tools",
      requiredPermissions: ["is_dev"]
    },
    pages: {
      Dev: [
        {
          displayName: "Dev Tools",
          icon: "mdi-wrench-cog",
          requiredPermissions: ["is_dev"]
        },
      ],
    }
  },
}

function getPage(page_slug) {
  for (const app of Object.values(navPages)) {
    if (app.pages) {
      for (const pageGroup of Object.values(app.pages)) {
        for (const page of pageGroup) {
          if (page_slug == functions.nameToSlug(page.displayName)) {
            return page
          }
        }
      }
    }
  }
  return null
}

// Returns a copy of navPages, but with unauthorized pages taken out.
function getAuthorizedPages(user) {
  let authorizedPages = {}

  for (const [appKey, app] of Object.entries(navPages)) {
    if (authorizePage(app.meta, user)) {
      authorizedPages[appKey] = { "meta": app.meta }
      if (app.pages) {
        authorizedPages[appKey]["pages"] = {}
        // What pages from each app can the user see?
        for (const [groupKey, pageGroup] of Object.entries(app.pages)) {
          authorizedPages[appKey]["pages"][groupKey] = []

          for (let page of pageGroup) {
            if (authorizePage(page, user)) {
              authorizedPages[appKey]["pages"][groupKey].push(page) // User can see this page
            }
          }

          if (authorizedPages[appKey]["pages"][groupKey].length == 0) {
            delete authorizedPages[appKey]["pages"][groupKey] // Remove empty page groups
          }
        }
      }
    }
  }

  return authorizedPages
}

// Returns a list of the meta information for each app the user is authorized to access
function getAuthorizedApps(user) {
  let authorizedApps = []
  let authorizedPages = getAuthorizedPages(user)

  for (const app of Object.values(authorizedPages)) {
    authorizedApps.push(app.meta)
  }

  return authorizedApps
}

// Checks if a user is authorized to access a given page
function authorizePage(page, user) {
  if (page && user) {
    if ("requiredPermissions" in page) {
      for (const role of page.requiredPermissions) {
        if (!user.permissions[role]) {
          return false
        }
      }
    }
  }

  return true
}

export default router
export { navPages, getPage, getAuthorizedPages, getAuthorizedApps, authorizePage }

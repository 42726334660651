var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.wordList.length == 0)?_c('v-container',[_c('v-row',{staticClass:"justify-center align-center"},[_c('v-col',{staticClass:"justify-center align-center"},[_c('v-card',{staticClass:"pa-4"},[(_vm.dataLoading)?_c('span',[(_vm.dataLoading)?_c('v-progress-circular',{attrs:{"color":"primary","size":200,"width":4,"indeterminate":""}},[_vm._v(" Data Loading...")]):_vm._e()],1):_c('span',{staticClass:"secondary--text"},[_c('b',[_vm._v(" Great work! ")]),_vm._v("You've practiced all your recall words for today. "),_c('br'),_c('br'),_vm._v(" Check in again tomorrow or go to the \"Learn\" or \"Production\" tabs to keep studying. "),_c('br'),_c('br'),_c('core-btn-secondary',{on:{"click":function($event){return _vm.navigate('flashcards', 'learn')}}},[_vm._v("Learn")]),_c('br'),_c('br'),_c('core-btn-secondary',{on:{"click":function($event){return _vm.navigate('flashcards', 'production')}}},[_vm._v("Production")])],1)])],1)],1)],1):_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"10","lg":"7","xl":"6"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"d-flex justify-center align-center"},[_c('v-tooltip',{attrs:{"color":"secondary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(_vm.topWord.lexeme.language in ['Japanese', 'Chinese'])?_c('h3',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.topWord.lexeme.word)+" "+_vm._s(_vm.flipped ? ("(" + (_vm.topWord.lexeme.pronounciation) + ")") : "")+" ")]):_c('h3',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.flipped ? _vm.topWord.lexeme.word : "")+" ")])])]}}])},[_c('span',[_vm._v("Meanings: "+_vm._s(_vm.topWord.lexeme.english_meanings)+" ")])]),_c('v-tooltip',{attrs:{"color":"secondary","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openURL(_vm.dictionaryURL)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)]}}])},[_c('span',[_vm._v(" Open in Dictionary")])])],1)],1),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"6"}},[_c('v-img',{staticClass:"image",attrs:{"src":_vm.topWord.image_url,"width":300}})],1)],1),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"d-flex justify-center align-center"},[_c('v-tooltip',{attrs:{"color":"secondary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text-body-1"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.blankedSentence)+" ")])]}}])},[_c('span',{staticClass:"ma-1"},[_c('v-row',[_vm._v(_vm._s(_vm.topWord.sentence_translation))]),(_vm.topWord.sentence_pronounciation && _vm.flipped)?_c('v-row',[_c('span',{staticClass:"text-body-1",domProps:{"innerHTML":_vm._s(_vm.topWord.sentence_pronounciation)}})]):_vm._e()],1)]),_c('v-tooltip',{attrs:{"color":"secondary","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openURL(_vm.topWord.sentence_url)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)]}}])},[_c('span',[_vm._v(" Open in Tatoeba")])])],1),(_vm.flipped)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('audio',{attrs:{"controls":"","autoplay":"","src":_vm.topWord.sentence_audio_src}})]):_vm._e()],1),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"d-flex justify-center align-center"},[(!_vm.flipped && _vm.topWord.hint)?_c('v-tooltip',{attrs:{"color":"secondary","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" Hint ")])]}}],null,false,3517824708)},[_c('span',[_vm._v(" "+_vm._s(_vm.topWord.hint))])]):_c('v-textarea',{attrs:{"label":"Notes","outlined":""},model:{value:(_vm.topWord.hint),callback:function ($$v) {_vm.$set(_vm.topWord, "hint", $$v)},expression:"topWord.hint"}})],1)],1)],1)],1),_c('v-col',{staticClass:"mt-md-5",attrs:{"sm":"12","md":"2","xl":"1"}},[(!_vm.flipped)?_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('core-btn-primary',{attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.flip()}}},[_vm._v(" Flip "),_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1):_vm._e(),(_vm.flipped)?_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('core-btn-primary',{attrs:{"inverted":"","loading":_vm.loading},on:{"click":function($event){return _vm.submit(false)}}},[_vm._v(" Wrong "),_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1):_vm._e(),(_vm.flipped)?_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('core-btn-secondary',{attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.submit(true)}}},[_vm._v(" Correct "),_c('v-icon',[_vm._v("mdi-check")])],1)],1)],1):_vm._e()],1)],1),_c('br'),_c('hr'),_c('translator')],1)}
var staticRenderFns = []

export { render, staticRenderFns }